import React from "react";
import { Link, graphql } from "gatsby"
import { GatsbySeo, BreadcrumbJsonLd } from 'gatsby-plugin-next-seo';
import { GetSiteMetadata } from 'hooks/GetSiteMetadata';
import { GatsbyImage, getSrc } from "gatsby-plugin-image"
import { renderRichText } from "gatsby-source-contentful/rich-text"

// nodejs library that concatenates classes
import classNames from "classnames";

// core components
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import HeaderTemplate from "components/Header/HeaderTemplate.js";
import HeaderScentsy from "components/Header/HeaderScentsy.js";
import FooterTemplate from "components/Footer/FooterTemplate.js";

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Breadcrumbs } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Hidden from "@material-ui/core/Hidden";

import useIsClient from 'hooks/useIsClient';

import styles from "assets/jss/material-kit-pro-react/views/productCategoryTemplateStyles.js";

export const query = graphql`
  query ($slug: String!, $bannerImageName: String!) {
    bannerImage: imageSharp(resize: {originalName: {eq: $bannerImageName}}) {
      gatsbyImageData(quality: 50)
    }
    bannerImageMobile: imageSharp(resize: {originalName: {eq: $bannerImageName}}) {
      gatsbyImageData(width: 1200)
    }
    contentfulCategory (slug: {eq: $slug})
    {
      name
      bannerImageName
      image {
        gatsbyImageData(placeholder: BLURRED)
        file {
          fileName
        }
      }
      richDescription {
        raw
      }
    }
    allContentfulProductFamily (
      filter: {category: {elemMatch: {slug: {eq: $slug}}}, isHidden: {eq: false}},
      sort: { fields: order, order: ASC }
      )
      {
        edges {
            node {
                name
                slug
                shortDescription
                image {
                  gatsbyImageData(placeholder: BLURRED)
                }
            }
        }
    } 
  }
`

const useStyles = makeStyles(styles);

// define options for documentToReactComponents (json > richText)
const options = {
  renderNode: {
    "embedded-asset-block": (node) => {
      const alt = node.data.target.fields.title['en-CA']
      const url = node.data.target.fields.file['en-CA'].url

      return <img alt={alt} src={url} />
    }
  }
}

export default function ProductCategoryTemplate(props) {

  const { isClient } = useIsClient();
  if (isClient) {
    var isMobile = window.matchMedia('(max-width: 600px)').matches;
    var bannerImage;
    if (isMobile) {
      bannerImage = getSrc(props.data.bannerImageMobile)
    } else {
      bannerImage = getSrc(props.data.bannerImage)
    }
  }

  var appendToH1;
  if (props.pageContext.locale.ogLanguageCode === "en_CA") {
      appendToH1 = "Scentsy Catalog Canada";
  } else {
      appendToH1 = "Scentsy Catalog USA";
  }

  // for SEO path
  const { siteMetadata } = GetSiteMetadata();
  var path = props.location.pathname;
  if (path.includes("/usa")) {
    path = path.replace("/usa", "");
  }

  const classes = useStyles();

  return (
    <div>
      <GatsbySeo
        title={props.pageContext.name + props.pageContext.locale.appendToTitle}
        description={props.pageContext.shortDescription}
        canonical={siteMetadata.siteUrl + props.pageContext.locale.path + path}
        language={props.pageContext.locale.isoLanguageCode}
        languageAlternates={[
          {
            hrefLang: props.pageContext.locale.isoLanguageCode,
            href: siteMetadata.siteUrl + props.pageContext.locale.path + path,
          },
          {
            hrefLang: props.pageContext.altLocale.isoLanguageCode,
            href: siteMetadata.siteUrl + props.pageContext.altLocale.path + path,
        }
        ]}
        openGraph={{
          type: 'website',
          title: props.pageContext.name + props.pageContext.locale.appendToTitle,
          description: props.pageContext.shortDescription,
          url: siteMetadata.siteUrl + props.pageContext.locale.path + path,
          site_name: 'SCENTSY Online Store | Home Fragrance Biz',
          locale: props.pageContext.locale.ogLanguageCode,
          images: [
            {
              url: siteMetadata.siteUrl + '/files/images/product/' + props.data.contentfulCategory.image.file.fileName,
              alt: 'Picture of ' + props.pageContext.name,
              width: 600,
              height: 600
            }]
        }}
      />
      <BreadcrumbJsonLd
        itemListElements={[
          {
            position: 1,
            name: 'Home',
            item: 'https://homefragrancebiz.com',
          },
          {
            position: 2,
            name: 'SCENTSY catalog',
            item: siteMetadata.siteUrl + props.pageContext.locale.path + path,
          },
          {
            position: 3,
            name: props.pageContext.name,
          },
        ]}
      />
      <HeaderTemplate
        locale={props.pageContext.locale}
        location={props.location}
      />
      <Parallax
        filter={"dark"}
        image={bannerImage}
        small
        //style={{ paddingTop: "3%", height: "70vw", maxHeight: "500px" }}
      >
        <div className={classes.container}>
          <HeaderScentsy />
          <GridContainer>
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >


              <div className={classes.brand}>
                <Hidden only={['sm','md','lg','xl']} implementation="js">
                  <Typography variant="h5" component="h1" className={classes.title}>
                    {props.pageContext.name}
                  </Typography>
                </Hidden>
                <Hidden only={['xs']} implementation="js">
                  <h1 className={classes.title}>
                    {props.pageContext.name}
                  </h1>
                </Hidden>
                <Hidden only={['xs']} implementation="js">
                  <Typography variant="caption" component="p" className={classes.titleDescription}>
                    {props.pageContext.shortDescription}
                  </Typography>
                </Hidden>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>

      <div className={classNames(classes.main, classes.mainRaised)}>

        <Paper className={classes.paper}>

            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
              <Typography variant="subtitle2" component="p">
                <Link color="inherit" to={props.pageContext.locale.path + '/shop/'} >
                  SHOP
                </Link>
              </Typography>
              <Typography color="textPrimary" variant="subtitle2" component="h1">
                {props.pageContext.name.toUpperCase()}
              </Typography>
            </Breadcrumbs>

            <Hidden smDown implementation="js">
              <font color="#777777" size="1"><i> {appendToH1}</i></font>
            </Hidden>

            <hr></hr>

            <Hidden xsDown implementation="js">
              <Grid
                container
                direction="row"
                justifyContent="center"
                spacing={3}

              >
                <Grid item xs={8} sm={4}>
                  <GatsbyImage image={props.data.contentfulCategory.image.gatsbyImageData} alt={props.data.contentfulCategory.name} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h4" component="p">{props.data.contentfulCategory.name}</Typography>


                    {renderRichText(props.data.contentfulCategory.richDescription, options)}

                </Grid>

              </Grid>
              <p></p>
            </Hidden>
              <GridContainer>

                <GridItem md={12} sm={12}>
                  <GridContainer>
                    {props.data.allContentfulProductFamily.edges.map((edge) => (
                      <GridItem md={4} sm={4} key={edge.node.slug}>
                        <Link to={props.pageContext.locale.path + "/shop/" + edge.node.slug + "/" }>
                        <Card plain product>
                          <CardHeader noShadow image>
                              <GatsbyImage image={edge.node.image.gatsbyImageData} alt={edge.node.name} />
                          </CardHeader>
                          <CardBody plain>

                            <Typography variant="h6" component="h2" className={classes.cardTitle}>
                              {edge.node.name}
                            </Typography>
                            <p className={classes.description}>
                              {edge.node.shortDescription}
                            </p>
                          </CardBody>
                          
                        </Card>
                      </Link>
                    </GridItem>

                    ))}

                  </GridContainer>
                </GridItem>
              </GridContainer>
              
        </Paper>


      </div>
      <FooterTemplate
        locale={props.pageContext.locale}
      />
    </div>
  );
}
